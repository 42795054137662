import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"

import "../scss/layout.scss"


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
        // style={{
        //   margin: `0 auto`,
        //   marginTop: `50px`,
        //   maxWidth: 960,
        //   padding: `0px 1.0875rem 1.45rem`,
        //   paddingTop: 0,
        // }}
        >
          <main>{children}</main>

        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
