import React from 'react';
import { Link } from 'gatsby';
import Menu from './Menu';
import Hamburger from './Hamburger';
import logo from '../images/logo-web.svg';
import logoMobile from '../images/logo-mobile.svg';


import MenuMobile from './MenuMobile';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false,
    };
  }

  toggleMenu = menuActive => {
    console.log('toggled', menuActive);
    this.setState(prevState => ({
      menuActive: !prevState.menuActive,
    }));
  };

  render() {
    return (
      <div className="header header-fixed">
        <div className="container">

          <div className="logo">
            <Link to="/">
              {/* <Img fluid={data.imageOne.childImageSharp.fluid} /> */}
              <img alt="Figurit Homepage" src={logo} />
            </Link>
          </div>

          <div className="logo-mobile">
            <Link to="/">
              <img alt="Figurit Homepage" src={logoMobile} />

            </Link>
          </div>



          <MenuMobile active={this.state.menuActive} toggleButton={this.toggleMenu} />
          <Menu />
          <Hamburger toggleMenu={this.toggleMenu} active={this.state.menuActive} />
        </div>
      </div>
    );
  }
}

export default Header;