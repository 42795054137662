import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';

import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'


const MenuMobile = props => {
  const { menuLinks } = props.data.site.siteMetadata;
  // console.log(props);
  return (
    <div
      id="main-menu-mobile"
      className={`main-menu-mobile ${props.active ? 'open' : ''}`}
    >

      <Scrollspy items={[...menuLinks.name]}>
        {menuLinks.map(link => (
          <li key={link.name} onClick={props.toggleButton}>
            <Scroll type="id" element={link.name}>
              <button>{link.name}</button>
            </Scroll>
          </li>

        ))}
      </Scrollspy>
    </div >
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query MenuMobileQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <MenuMobile active={props.active} data={data} toggleButton={props.toggleButton} />}
  />
);
