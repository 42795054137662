import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const Menu = (props) => {
  const { menuLinks } = props.data.site.siteMetadata;
  return (
    <div id="main-menu" className="main-menu">
      <Scrollspy items={[...menuLinks.name]} currentClassName="active" offset={0}>
        {menuLinks.map(link => (
          <li key={link.name}>
            <Scroll type="id" element={link.name}>
              <button>{link.name}</button>
            </Scroll>
          </li>

        ))}
      </Scrollspy>

      {/*       <Scrollspy items={['intro']} currentClassName="is-active" offset={-300}>
        <li>
          <Scroll type="id" element="intro">
            <button>Introduction</button>
          </Scroll>
        </li>
      </Scrollspy>
      <ul>
        {menuLinks.map(link => (
          <li key={link.name}>
            <Link to={link.link}>{link.name}</Link>
          </li>
        ))}
      </ul> */}


    </div >
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery2 {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <Menu data={data} />}
  />
);
