import React from 'react';

class Hamburger extends React.Component {

  /* Don't really need state here hamburger relies on the active state of header */

  constructor(props) {
    super(props);
    this.state = {
      hamburgerActive: false,
    };
  }


  handleToggle = () => {
    this.props.toggleMenu();
  };

  render() {
    return (
      <button
        id="toggle-main-menu-mobile"
        className={`hamburger hamburger--slider ${
          this.props.active ? 'is-active' : ''
          }`}
        type="button"
        onClick={this.handleToggle}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
    );
  }
}

export default Hamburger;
